

















import { RouterNames } from "@/router/routernames";
import { grupo_usuario } from "@/shared/dtos/grupo_usuarios/grupo_usuario";
import { grupo_usuarioAllDto } from "@/shared/dtos/grupo_usuarios/grupo_usuarioAllDto";
import { MessageService } from "@/shared/services/message-service";
import grupo_usuarioModule from "@/store/modules/grupo_usuario-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class GrupoLista extends Vue {
  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(obj: grupo_usuario) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: grupo_usuario) {
    grupo_usuarioModule
      .eliminargrupo_usuarioficha(new grupo_usuarioAllDto(obj))
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados a este grupo de usuarios",
          MessageService.TypeWarning
        );
      });
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.gruposformulario,
      params: { id: id },
    });
  }
}
